import axios from '@/config/axios'

class SaleVolumeService {
  async fetchAgentSaleVolume(agent_id) {
    try {
      const resp = await axios.get(`/sale_volume/${agent_id}`)
      return resp.data
    } catch (error) {
      throw error.response
    }
  }

  async fetchSaleVolume() {
    try {
      const resp = await axios.get('/sale_volume')
      return resp.data
    } catch (error) {
      throw error.response
    }
  }
}

export default new SaleVolumeService()
