<template>
  <div class="row w-100 m-0 text-start">
    <div class="col-12">
      <h2>Agent Level</h2>
    </div>

    <div class="col-12 mt-4">
      <DataTable
        :value="volumes"
        responsiveLayout="scroll"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="numRows"
        :loading="loading"
        sortMode="multiple"
        removableSort
        dataKey="id"
        v-model:filters="filters"
        ref="dt"
      >
        <template #header>
          <div class="row justify-content-between">
            <div class="col-3">
              <div class="d-flex align-items-center">
                <p class="text-start m-0 me-3">Show</p>
                <select class="form-control" v-model="numRows" style="width: 50px">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
              </div>
            </div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
            </div>
          </div>
        </template>
        <Column field="year" header="Year" :sortable="true"></Column>
        <Column field="agent" header="Agent" :sortable="true"></Column>
        <Column field="team" header="Team" :sortable="true"></Column>
        <Column field="level_id" header="Level" :sortable="true"></Column>
        <Column field="total_sales" header="Total Sales" :sortable="true"></Column>

        <template #footer>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="exportCSV">Export CSV</button>
          </div>
        </template>
        <template #loading> <p class="text-center">Loading records, please wait...</p> </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import SaleVolumeService from '../../services/sale_volume.service'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useDatatable } from '../../hooks/useDatatable'

export default {
  components: { DataTable, Column },
  setup() {
    const volumes = ref([])
    const { loading, filters, numRows, dt, exportCSV } = useDatatable()

    onMounted(async () => {
      loading.value = true
      const resp = await SaleVolumeService.fetchSaleVolume()
      volumes.value = resp.map((v) => ({
        id: v.id,
        agent_id: v.agent_id,
        year: v.year,
        total_sales: v.total_sales,
        level_id: v.level_id,
        agent: `${v.agent.name} ${v.agent.last_name}`,
        team: v.agent.team?.name,
      }))
      loading.value = false
    })

    return { volumes, loading, filters, numRows, dt, exportCSV }
  },
}
</script>
